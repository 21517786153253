import { A } from "@solidjs/router";
import { classNames } from "@core/lib/classNames";
import styles from "./StorytellLogo.module.css";

type Modifier = "spacing";
interface Props {
  modifiers?: Modifier[];
}

export const StorytellLogo = (props: Props) => (
  <A
    class={classNames(
      styles["storytell-logo"],
      props.modifiers ? props.modifiers.map((modifier) => styles[`storytell-logo--${modifier}`]).join(" ") : "",
    )}
    href="/"
  >
    <span class="screen-reader">Go to homepage</span>
    <span class={styles["storytell-logo__image"]} role="presentation" />
  </A>
);
