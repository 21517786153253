type Themes = "light" | "dark";

export class AppTheme {
  public overriden = false;
  public activeTheme: Themes = "light";
  private classes: Themes[] = ["light", "dark"] as const;
  /**
   * Determines whether the browser is set to `light` or `dark` mode.
   */
  public getBrowserScheme() {
    return globalThis?.matchMedia?.("(prefers-color-scheme: dark)").matches ? "dark" : "light";
  }

  /**
   * Set class to match the theme.
   */
  private set(theme: Themes) {
    this.activeTheme = theme;
    globalThis.document.documentElement.classList.add(theme);

    const classes = this.classes.filter((entry) => entry !== theme);
    let count = classes.length;
    while (count) {
      count = count - 1;
      const current = classes[count];
      if (current) {
        globalThis.document.documentElement.classList.remove(current);
      }
    }
  }

  /**
   * Handler browser color scheme changes.
   */
  private eventHandler() {
    const theme = this;
    return function colorSchemeListener(event: MediaQueryListEvent) {
      if (theme.overriden === false) {
        const setting = event.matches ? "dark" : "light";
        theme.set(setting);
      }
    };
  }

  /**
   * Event listener for theme changes.
   */
  public listen() {
    if ("matchMedia" in globalThis && this.overriden === false) {
      const browser = globalThis.matchMedia("(prefers-color-scheme: dark)");
      if (this.overriden === false) {
        this.set(browser.matches ? "dark" : "light");
      }
      browser.addEventListener("change", this.eventHandler());
      return true;
    }

    return false;
  }
}
